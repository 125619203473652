import React from "react"
import GridLayout from "../GridLayout"
import styled, { css } from "styled-components"
import device from "../../util/devices"

const Container = styled(GridLayout)`
  margin-top: 230px;

  padding-bottom: 150px;
  img {
    border-radius: 14px;
    overflow: hidden;
    display: block;
  }
  small {
    font-size: 12px;
    margin-top: 16px;
    display: block;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  grid-row-gap: 48px;
  .photo {
    grid-column: 1 / 4;
    max-width: 207px;
  }
  .person1, .person2 {
    grid-column: 1 / 7;
  }
  @media (${device.tablet}) {
    .photo {
      grid-column: 1;
    }
    .person1 {
      grid-column: 3 / 5;
    }
    .person2 {
      grid-column: 5 / 7;
    }
  }
  @media (${device.desktop}) {
    .photo {
      grid-column: 2 / 4;
    }
    .intro {
      grid-column: 2 / 4;
    }
    .person1 {
      grid-column: 5 / 8;
    }
    .person2 {
      grid-column: 9 / 12;
    }
    *[class^="person"] {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
`

const AboutUsSection = props => (
  <Container {...props}>{props.children}</Container>
)

export default AboutUsSection
