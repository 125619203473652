import React from "react"
import GridLayout from "../../layout/GridLayout"
import styled, { css } from "styled-components"
import BrandCard from "../BrandCard"
import { navigate } from "gatsby"
import { urlFor } from "../../util/url"
import device from "../../util/devices"

const LayoutContainer = styled(GridLayout)`
  padding-top: clamp(120px, ${((200 / 1440) * 100).toFixed(2)}vw, 200px);
  grid-column-start: 2;
`
const BrandCardList = styled(GridLayout)`
  grid-column: 1 / 7;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  @media (${device.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (${device.desktop}) {
    grid-column: 2 / 12;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 64px 16px;
  }
  width: auto !important;
  margin: 0 !important;
`

const BrandsGrid = ({ brands }) => {
  return (
    <LayoutContainer howMany={brands.length} id={"brands-showcase"}>
      <BrandCardList>
        {brands.map(b => (
          <BrandCard
            height="100%"
            brand={b}
            clickable={true}
            onBrandClick={() =>
              navigate(urlFor("DatoCmsBrand", b.slug), {
                state: { modal: true },
              })
            }
          />
        ))}
      </BrandCardList>
    </LayoutContainer>
  )
}

export default BrandsGrid
