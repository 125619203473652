import styled from "styled-components"
import device from "../../util/devices"

export const Container = styled.div`
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: column;
  z-index: 20;
  display: none;

  @media (${device.desktop}) {
    display: flex;
  }

  > * {
    border-radius: 50%;
    border: 2px solid #171717;
    width: 29px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-out;
    box-sizing: border-box;
  }
  > :nth-child(2) {
    margin-top: 23px;
    transform: rotate(180deg);
  }

  > .available {
    background: #040404;
    color: white;
    cursor: pointer;
  }
`
