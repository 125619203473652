import React from "react"
import GridLayout from "../GridLayout"
import styled, { css } from "styled-components"
import device from "../../util/devices"

const Container = styled(GridLayout)`
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  ${[1, 2, 3].map(
    i => css`
      > :nth-child(${i}) {
        grid-row: ${i};
        grid-column: 1 / 7;
        margin-top: 80px;
        @media (${device.tablet}) {
          grid-column: 2 / 6 ;
        }
      }
    `
  )};

  @media (${device.desktop}) {
    > :first-child {
      margin-top: 90px;
    }
    ${[1, 2].map(
      i => css`
        > :nth-child(${i}) {
          grid-row: ${i};
          grid-column: 5 / 9;
          :not(:first-child) {
            margin-top: 30px;
          }
        }
      `
    )};

    > :nth-child(3) {
      grid-row: 2 / 4;
      grid-column: 8 / 11;
      margin-top: 90px;
    }
  }
`

const IntroductionSection = ({ children }) => (
  <Container as="section"> {children}</Container>
)

export default IntroductionSection
