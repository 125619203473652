import React from "react"
import styled, { css } from "styled-components"
import GridLayout from "../GridLayout"
import device from "../../util/devices"

const Container = styled(GridLayout)`
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  padding-top: clamp(100px, ${(200/1440*100).toFixed(2)}vw, 200px);
  grid-row-gap: 48px;
  > div {
    grid-column: 1 / 7;
  }
  @media (${device.tablet}) {
    > div:nth-child(1) {
      grid-column: 1 / 7;
    }
    > div:nth-child(2) {
      grid-column: 1 / 4;
    }
    > div:nth-child(3) {
      grid-column: 4 / 7;
    }
  }
  @media (${device.desktop}) {
    grid-row-gap: 48px;
    > div:nth-child(1) {
      grid-column: 2 / 7;
    }
    > div:nth-child(2) {
      grid-column: 2 / 7;
    }
    > div:nth-child(3) {
      grid-column: 7 / 12;
    }
  }
  ul {
      padding: 0;
      margin: 16px 0 0 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    li {
      list-style: none;
      padding-left: 1.5em;
      position: relative;
    }
    li:before {
      content: '';
      display: block;
      position: absolute;
      background: black;
      width: 3px;
      height: 3px;
      left: 0.66em;
      top: 0.475em;
      border-radius: 50%;
    }
`

const WhatWeDoSection = ({ children, ...props }) => (
  <Container as="section" {...props}>
    {children}
  </Container>
)

export default WhatWeDoSection
