import React, { useState } from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Body1, Body2, ImageCaption, Name, Title1 } from "../components/Texts"
import DatoCmsText from "../components/DatoCmsText"
import IntroductionSection from "../layout/index/Introduction"
import Img from "gatsby-image/withIEPolyfill"
import WhatWeDoSection from "../layout/index/WhatWeDo"
import Button from "../components/Button"
import device from "../util/devices"
import styled from "styled-components"
import BackgroundContext from "../context/BackgroundContext"
import ContactUsModal from "../components/ContactUsModal"
import SectionPager from "../components/SectionPager"
import PageTransition from "gatsby-plugin-page-transitions"
import AboutUsSection from "../layout/index/AboutUs"
import BrandsGrid from "../components/BrandsGrid"
import ParallaxImage from "../components/ParallaxImage"

const ContactButton = styled(Button)`
  display: none;
  @media (${device.desktop}) {
    display: inline-block;
    min-width: 270px;
  }
`

const IndexPage = ({ data: { datoCmsHomePage, datoCmsSite }, location }) => {
  const bg = React.useContext(BackgroundContext)
  bg.setLinearBackgroundColors(null)

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <PageTransition>
        <main style={{ scrollBehavior: "smooth" }}>
          <HelmetDatoCms
            seo={datoCmsHomePage.seoMetaTags}
            favicon={datoCmsSite.faviconMetaTags}
          />
          {/* <SectionPager /> */}
          <IntroductionSection>
            <DatoCmsText
              WrapperComponent={Body1}
              text={datoCmsHomePage.introduction}
            />
            <DatoCmsText
              WrapperComponent={Body2}
              text={datoCmsHomePage.introductionPart2}
            />
            {/* <ParallaxImage
              as="div"
              image={datoCmsHomePage.introImage}
              objectFit={"cover"}
            >
              <ImageCaption>{datoCmsHomePage.introImage.title}</ImageCaption>
            </ParallaxImage> */}
          </IntroductionSection>
          <BrandsGrid brands={datoCmsHomePage.featuredBrands} />

          <WhatWeDoSection id="services">
            <div>
              <DatoCmsText
                WrapperComponent={Body1}
                text={datoCmsHomePage.whatWeDoIntroduction}
              />
            </div>
            {datoCmsHomePage.areas.map((a, i) => (
              <div key={i}>
                <strong
                  className="area-title"
                  css={{ textTransform: "lowercase" }}
                >
                  {a.title}
                </strong>
                <DatoCmsText
                  className="area-desc"
                  text={a.description}
                  WrapperComponent={Body2}
                />
              </div>
            ))}
          </WhatWeDoSection>

          <AboutUsSection id={"founders"}>
            <div className="photo">
              <Img
                objectFit="contain"
                objectPosition="left top"
                {...datoCmsHomePage.teamPhoto}
              />
              <small>{datoCmsHomePage.teamPhoto.title}</small>
            </div>
            {datoCmsHomePage.people.map(({ name, description }, i) => (
              <div key={name} className={"person" + (i + 1)}>
                <Name>{name}</Name>
                <DatoCmsText WrapperComponent={Body2} text={description} />
              </div>
            ))}
          </AboutUsSection>
        </main>
        {modalOpen && <ContactUsModal onClose={() => setModalOpen(false)} />}
      </PageTransition>
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsHomePage {
      introduction
      introductionPart2
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      introImage {
        ...Image
      }

      whatWeDoIntroduction
      areas {
        title
        description
      }
      featuredBrands {
        ...BrandCard
      }
      aboutUsIntroduction
      teamPhoto {
        ...Image
      }
      people {
        name
        description
      }
    }
  }
`
